.legacy-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.legacy-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.legacy-content {
  width: 70%;
}

.legacy-content p {
  text-align: center;
}

@media only screen and (max-width: 1024px){
  .legacy-content{
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .legacy-content {
    width: 100%;
    margin: 0;
  }
}

@media (max-width:600px) {

  .legacy-container {
    gap: 1.2rem;
  }
}