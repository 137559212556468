footer {
  background: #25242a;
}

.footer {
  font-size: 30px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.footer-flex {
  display: flex;
  gap: 10rem;
}

.footer-form {
  width: calc(48% - 6.66rem);
}

.footer-address {
  width: calc(30% - 6.66rem);
}

.footer-contact {
  width: calc(30% - 6.66rem);
}

.footer-form p {
  color: #fff;
  font-weight: 300;
  margin-bottom: 2.5rem;
}

.footer-form-tag {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.footer-form-tag input,
.footer-form-tag textarea {
  width: 80%;
  border: unset;
  border-bottom: 1px solid #656565;
  background: unset;
  padding: 0.5rem 0;
  color: #fff;
  font-size: 16px;
}

.footer-form-tag input::placeholder,
.footer-form-tag textarea::placeholder {
  font-size: 16px;
  font-family: "Gothic";
  color: #a4a4a4 !important;
}

.footer-form-tag input:focus,
.footer-form-tag textarea:focus {
  outline: unset;
}

.footer--submit {
  padding: 0.5rem 2rem;
  border: 1px solid;
  color: #fff;
  position: relative;
  transition: all 0.9s ease;
  background-color: transparent;
  width: 30%;
  z-index: 0;
  font-size: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
}

.footer--submit:hover {
  color: #1a1d33;
}

.footer--submit:before {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: -1;
  transition: width 0.5s ease;
}

.footer--submit:hover:before {
  width: 100%;
}

.address h3 {
  font-size: 17px;
  font-family: "Gothic";
  color: #a4a4a4;
  font-weight: 400;
  margin-bottom: 0.6rem;
}

.address address {
  font-size: 17px;
  font-weight: 400;
  color: #fff;
  font-family: "Gothic";
  font-style: unset;
}

.opening-hours {
  margin-top: 2rem;
}

.opening-hours h3 {
  font-size: 17px;
  font-family: "Gothic";
  color: #a4a4a4;
  font-weight: 400;
  margin-bottom: 0.6rem;
}

.opening-hours p {
  font-size: 17px;
  font-weight: 400;
  color: #fff;
}

.footer-contact {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.phone-div h3 {
  font-size: 17px;
  font-family: "Gothic";
  color: #a4a4a4;
  font-weight: 400;
  margin-bottom: 0.6rem;
}

.phone-div p {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.phone-div a {
  font-size: 17px;
  font-weight: 400;
  color: #fff;
}

.email-div h3 {
  font-size: 17px;
  font-family: "Gothic";
  color: #a4a4a4;
  font-weight: 400;
  margin-bottom: 0.6rem;
}

.email-div p {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.email-div a {
  font-size: 17px;
  font-weight: 400;
  color: #fff;
}

.social-div h3 {
  font-size: 17px;
  font-family: "Gothic";
  color: #a4a4a4;
  font-weight: 400;
  margin-bottom: 1rem;
}

.social-div p {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.social-icons {
  display: flex;
  gap: 2rem;
}

.social-icons img {
  cursor: pointer;
}

.copywright-section {
  padding: 2rem 0;
  background-color: #fff;
}

.copywright-section p {
  font-size: 15px;
  color: #000;
  text-align: center;
}

.signatures-logo p {
  font-size: 14px;
  color: #949494;
}

.signatures-logo {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 0.5rem;
  align-items: center;
}

@media (max-width: 1440px) {
  .footer-form {
    width: calc(60% - 6.66rem);
  }
}

@media only screen and (max-width: 1280px) {
  .footer-flex {
    gap: 5rem;
  }

  .footer-form {
    width: calc(50% - 3.33rem);
  }

  .footer-address {
    width: calc(25% - 3.33rem);
  }

  .footer-contact {
    width: calc(25% - 3.33rem);
  }
}

@media only screen and (max-width: 1024px) {
  .footer-flex {
    flex-direction: column;
  }

  .footer-form {
    width: 100%;
  }

  .footer-address {
    width: 100%;
  }

  .footer-contact {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .footer-form-tag input::placeholder,
  .footer-form-tag textarea::placeholder {
    font-size: 14px;
  }

  .footer--submit {
    font-size: 16px;
  }
  .address address {
    font-size: 16px;
  }

  .footer-flex {
    gap: 3rem;
  }
  .footer-contact {
    gap: 1rem;
  }

  .copywright-section p {
    font-size: 13px;
  }
  .signatures-logo p {
    font-size: 12px;
  }
  .signatures-logo {
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
  }
}
