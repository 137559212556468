.right-section-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* width: 80%;
    margin: auto; */
    padding: 2rem 0;
}

.right-section-text {
    width: 46%;
}

.right-section-img {
    width: 48%;
    padding: 1rem 1rem 1rem 0;
}

.right-section-para h3 {
    font-family: "Gothic Bold", sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: #333333;
}


.right-section-para {
    margin-top: 1.6rem;
}

.right-section-img img {
    width: 100%;
    transition: all 0.5s ease;
}

.sub-right-sec {
    padding-left: 4rem;
}

.sub-right-sec h3 {
    font-size: 18px;
    font-weight: 700;
}

.sub-right-sec p {
    font-size: 18px;
    font-weight: 300;
}

.right-section-img:hover img {
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

@media (max-width: 1366px) {
    .right-section-flex {
        padding: 1.5rem 0;
    }
}



@media (max-width:1024px) {
    .right-section-flex {
        flex-direction: column-reverse;
    }

    .right-section-text {
        width: 100%;
    }

    .right-section-img {
        width: 100%;
        margin-top: 2rem;
    }
}

@media (max-width:600px) {
    .right-section-img {
        padding: 0;
    }
}