.products {
  background-color: #f7f8f1;
}

.products-intro h2 {
  margin-bottom: 1.2rem;
}

.products-sec-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 5rem 3.3rem;
  margin-top: 4rem;
  align-items: stretch;
}

.products-section {
  width: calc(33% - 3%);
  background: #d8e5d8;
  transition: all 0.5s ease;
  z-index: 0;
}

.products-section:hover {
  background: #1a1d33;
  box-shadow: 5px 5px 5px 0px #0000001a;
}
.products-section:hover h3 {
  color: #fff;
}

.products-section:hover p {
  color: #fff;
}

.products-section img {
  width: 100%;
  /* border-top-left-radius: 15px;
    border-top-right-radius: 15px; */
}

.products-section-text {
  padding: 1.5rem 1.2rem 3rem;
}

.products-section h3 {
  font-family: "Gothic Bold", sans-serif;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 0.6rem;
}

@media (max-width: 1366px) {
  .products-sec-flex {
    gap: 2rem;
  }
}

@media (max-width: 1024px) {
  .products-section {
    width: calc(50% - 3%);
  }
}

@media (max-width: 600px) {
  .products-section {
    width: 100%;
  }
}
