.science-of-beauty {
    background-color: #fff;
}

.sustainability {
    background-color: #F9F9F9;
}

.customization {
    background-color: #fff;
}

.multi-functionality {
    background-color: #F9F9F9;
}

.diversity {
    background-color: #F7F8F1;
}

.inclusivity {
    background-color: #fff;
}
.inclusivity-para  {
    margin: 2rem 0;
}

.inclusivity-para p {
    width: 85%;
}