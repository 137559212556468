/* fonts.css */

@font-face {
  font-family: "Gothic";
  font-weight: 400;
  font-style: normal;
  src: url("../public/fonts/CenturyGothic.ttf") format("truetype");
}

@font-face {
  font-family: "Gothic Bold";
  font-weight: 700;
  font-style: normal;
  src: url("../public/fonts/GOTHICB.TTF") format("truetype");
}
