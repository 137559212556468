.about-overview {
  background: #fff;
}

/* .aboutOverview-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
} */

/* temporary start */
.aboutOverview-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.aboutOverview-sec1 {
  width: 30%;
}
.aboutOverview-sec1:nth-of-type(2) {
  width: 30%;
}

.aboutOverview-sec1:nth-of-type(3) {
  width: 30%;
}

/* temporary end */

/* .aboutOverview-sec1 {
  width: 100%;
} */
/* .aboutOverview-sec1:nth-of-type(2) {
  width: 100%;
  margin-top: 1.2rem;
}
.aboutOverview-sec1:nth-of-type(3) {
  width: 100%;
  margin-top: 1.2rem;
}
.aboutOverview-sec1:nth-of-type(4) {
  width: 100%;
  margin-top: 1.2rem;
} */

.aboutOverview-sec2 {
  width: 100%;
}

.aboutOverview-inner:nth-of-type(2) {
  margin-top: 2rem;
}

.aboutOverview-sec2 h3 {
  font-family: "Gothic Bold", sans-serif;
  font-size: 38px;
  font-weight: 600;
}

.aboutOverview-sec2 p {
  font-size: 16px;
}

.statement-text {
  margin-top: 2rem;
}

.statement-text p {
  font-size: 16px;
}

@media (max-width: 1440px) {
  .aboutOverview-sec2 h3 {
    font-size: 34px;
  }
}

@media (max-width: 1366px) {
  .aboutOverview-flex {
    flex-direction: column;
  }

  .aboutOverview-sec1 {
    width: 100%;
  }
  .aboutOverview-sec1:nth-of-type(2) {
    width: 100%;
    margin-top: 1.2rem;
  }

  .aboutOverview-sec1:nth-of-type(3) {
    width: 100%;
    margin-top: 1.2rem;
  }
  .aboutOverview-sec2 {
    width: 100%;
    margin: 2rem 0 0 0;
  }
}

@media (max-width: 1024px) {
  .aboutOverview-sec2 h3 {
    font-size: 28px;
  }
}
