.inner-banner {
  position: relative;
  line-height: 0;
}

.inner-banner img {
  width: 100%;
}

.inner-banner--content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  height: auto;
  text-align: center;
  color: #fff;
  width: 100%;
  margin: auto;
  background: linear-gradient(
    180deg,
    rgb(0 0 0 / 50%) 0%,
    rgb(0 0 0 / 50%) 100%
  );
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.inner-banner--content h2 {
  font-size: 34px;
  font-weight: 400;
  line-height: 1.3;
  width: 90%;
}

.inner-banner--content h3 {
  font-size: 32px;
  font-weight: 400;
  line-height: 1.3;
  width: 85%;
}

.banner-tab-flex {
  width: 100%;
  margin: auto;
  /* padding: 1.5rem 0; */
  background: #f6f6f6;
  margin-top: -2rem;
  z-index: 1;
  position: relative;
  flex-wrap: nowrap;
  gap: 3rem;
  position: sticky;
  top: 85px;
  box-shadow: 0 -6px 10px 5px rgba(0, 0, 0, 0.5);
}
.banner-tab-flex-sec {
  width: 80%;
  padding: 1rem 0;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  flex-wrap: nowrap;
}

.banner-tab-flex a {
  font-size: 17px;
  color: #000;
  font-weight: 500;
  font-family: "Gothic Bold", sans-serif;
  position: relative;
}

.banner-tab-flex a::after {
  position: absolute;
  background: #314c6a;
  top: 100%;
  right: 100%;
  content: "";
  margin: 0.9rem 0 0 0;
  width: 0%;
  height: 3px;
  transition: right 0.3s ease;
}

.banner-tab-flex a.active::after {
  right: 0%; /* Move to the left side */
  width: 100%;
}

@media (max-width: 1440px) {
  .inner-banner--content h2 {
    font-size: 2.25rem;
  }

  .inner-banner--content h3 {
    font-size: 1.8rem;
  }
}

@media (max-width: 1366px) {
  .inner-banner--content h2 {
    font-size: 2.125rem;
  }

  .inner-banner--content h3 {
    font-size: 1.625rem;
  }
}

@media (max-width: 1024px) {
  .banner-tab-flex-sec {
    flex-wrap: wrap;
  }
  .banner-tab-flex a {
    font-size: 14px;
    width: 20%;
    text-align: center;
  }

  .banner-tab-flex {
    gap: 1rem;
  }

  .inner-banner--content h2 {
    font-size: 1.8rem;
  }

  .inner-banner--content h3 {
    font-size: 1.4rem;
  }
}

@media (max-width: 600px) {
  .inner-banner--content h2 {
    font-size: 1.4rem;
  }

  .inner-banner--content h3 {
    font-size: 1.2rem;
  }

  .banner-tab-flex a {
    width: 30%;
    font-size: 14px;
  }

  .banner-tab-flex {
    top: 60px;
  }

  /* line hide */
  .banner-tab-flex a::after {
    display: none;
  }
  .banner-tab-flex-sec {
    gap: 0.5rem;
  }
}

@media (max-width: 540px) {
  .banner-tab-flex a {
    width: 45%;
    font-size: 14px;
  }
}

@media (max-width: 450px) {
  .banner-tab-flex a {
    width: 100%;
    font-size: 12px;
  }

  .banner-tab-flex {
    gap: 0.5rem;
  }

  /* .banner-tab-flex {
    padding: 0.6rem 0;
  } */
}
