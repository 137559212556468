.positions {
  background: #f9f9f9;
}

.position-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d9d4d4;
  padding: 2rem 0 2rem 0;
}

.position-text {
  width: 60%;
}

.position-text h3 {
  font-family: "Gothic Bold", sans-serif;
  font-weight: 600;
  font-size: 24px;
}

.position-text p {
  font-weight: 300;
  font-size: 16px;
}

/* form */
.positions-form {
  background: #f7f8f1;
}

.career-form-flex {
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
}

.career-form-sec1,
.career-form-sec2 {
  width: 46%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.career-form input,
select {
  background: unset;
  outline: unset;
  border: unset;
  border-bottom: 1px solid #c5c5c5;
  padding: 1rem 0 0.5rem 0;
  font-size: 16px;
  color: #a4a4a4;
  font-weight: 400;
  font-family: "Gothic";
}

.career-form input::placeholder {
  color: #a4a4a4;
  font-size: 16px;
}

.career-form select::placeholder {
  color: #a4a4a4;
  font-size: 16px;
}

.career-btn-holder {
  width: 100%;
  text-align: right;
}

.career-btn {
  padding: 0.8rem 2rem;
  border: 1px solid;
  color: #333333;
  position: relative;
  transition: all 0.9s ease;
  background-color: transparent;
  width: max-content;
  font-family: "Gothic";
  font-size: 16px;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.career-btn:hover {
  color: #fff;
}

.career-btn:before {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #1a1d33;
  z-index: -1;
  transition: width 0.5s ease;
}

.career-btn:hover:before {
  width: 100%;
}

.career-submit {
  padding: 0.8rem 2rem;
  border: 1px solid;
  color: #333333;
  position: relative;
  transition: all 0.9s ease;
  background-color: transparent;
  width: max-content;
  font-family: "Gothic";
  font-size: 16px;
  z-index: 0;
}

.career-submit:hover {
  color: #fff;
}

.career-submit:before {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #1a1d33;
  z-index: -1;
  transition: width 0.5s ease;
}

.career-submit:hover:before {
  width: 100%;
  background-color: #1a1d33;
}

.file-input-container {
  position: relative;
  overflow: hidden;
}

.custom-file-label {
  display: inline-block;
  padding: 1rem 0 0.5rem 0;
  border-bottom: 1px solid #c5c5c5;
  color: #a4a4a4;
  font-family: "Gothic";
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

@media (max-width: 1024px) {

  .career-form-flex {
    width: 90%;
  }

  .career-btn {
    font-size: 16px;
  }

  .career-form input::placeholder {
    font-size: 16px;
  }

  .career-form select::placeholder {
    font-size: 16px;
  }

  .contact-form input {
    font-size: 16px;
  }

  .contact-form select {
    font-size: 16px;
  }

  .custom-file-label {
    font-size: 16px;
  }

  .career-submit {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .position-flex {
    align-items: flex-start;
    padding: 2rem 0 2rem 0;
    flex-direction: column;
    gap: 1.5rem;
  }

  .position-text {
    width: 100%;
  }

  .career-btn {
    padding: 0.6rem 2rem;
  }

  .position-text h3 {
    font-size: 20px;
  }


  .career-form-flex {
    width: 100%;
    flex-direction: column;
  }
  .career-form-sec1 {
    width: 100%;
  }
  .career-form-sec2 {
    width: 100%;
    margin-top: 1rem;
  }

  .career-form input::placeholder {
    font-size: 14px;
  }

  .career-form select::placeholder {
    font-size: 14px;
  }

  .contact-form input {
    font-size: 14px;
  }

  .contact-form select {
    font-size: 14px;
  }

  .custom-file-label {
    font-size: 14px;
  }
}
