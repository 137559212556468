.blogSections {
    background-color: #fff;
}

.blogSections-One-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.blogSections-One-text {
    width: 46%;
}

.blogSections-One-text h2 {
    margin-bottom: 1rem;
}

.blogSections-One-text p {
    font-size: 16px;
    margin-bottom: 1rem;
}

.blogSections-One-img {
    width: 46%;
}

.blogSections-One-img img {
    width: 97.1%;
    border-radius: 15px;
}


.green {
    background-color: #F7F8F1;
}

.grey {
    background-color: #F9F9F9;
}

.back-blog {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.back-blog img {
    width: 22.8%;
}

.back-blog a {
    font-family: "Dancing Script", cursive;
    font-size: 40px;
}

@media(max-width:1024px) {
    .blogSections-One-flex {
        flex-direction: column;
    }
    .even--blog{
        flex-direction: column-reverse;
    }

    .blogSections-One-text {
        width: 100%;
    }

    .blogSections-One-img {
        width: 100%;
        margin-top: 1rem;
    }
    .back-blog a {
        font-size: 30px;
    }
}