header {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 1rem 0;
  transition: background-color 0.3s ease;
  background-color: #fff;
}

/* .scrolled {
  background-color: #fff;
}
.scrolled .menu-list-ul li a {
  color: #000;
}
.scrolled .dot {
  background-color: #000;
}
.scrolled .logo .st0 {
  fill: #2A398F;
} */

.home{
  position: fixed;
  background-color: unset;
}

.home .navlink{
  color: #fff; 
}

.home.scrolled .navlink{
  color: #000;
}

.home .logo .st0{
  fill: #fff; 
}

.home.scrolled .logo .st0 {
  fill: #2A398F;
}

.home .menulist img{
  filter: unset; 
}

.home.scrolled .menulist img{
  filter: invert(100%);
}

.home .dot{
  background-color: #fff;
}

.home.scrolled .dot{
  background-color: #000;
}

.header-container {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  justify-content: space-between;
}

header.scrolled {
  background-color: #fff;
  color: #000;
}

.three-dots {
  gap: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85px;
  height: 85px;
  cursor: pointer;
}

.logo .st0 {
 fill: #2A398F;
}

/* .logo.open .st0 {
  fill: #2A398F;
  transition: all 0.3s ease;
} */

.three-dots.active {
  display: none;
}

.dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #000;
}

.logo {
  line-height: 0;
  position: relative;
  z-index: 1;
}
.logo img {
  width: 60%;
}

.logo .SVG_LOGO > g > path:nth-of-type(1) {
  fill: #1a1d33;
}

.logo .SVG_LOGO > g > path:nth-of-type(2) {
  fill: #58595b;
}

.logo .SVG_LOGO.menu-open > g > path:nth-of-type(1),
.logo .SVG_LOGO.menu-open > g > path:nth-of-type(2) {
  fill: #fff;
}

.close-btn {
  display: none;
  position: relative;
  z-index: 2;
  padding: 1.5rem 0;
}

.close-btn.active {
  display: flex;
}

.menulist{
  display: flex;
  align-items: flex-start;
  gap: 6rem;
}

.menu-list-ul {
  list-style-type: none;
  display: flex;
  gap: 4rem;
}

.menu-list-ul li{
  list-style-type: none;
}

.menu-list-ul li a {
  font-family: "Gothic";
  font-size: 16px;
  font-weight: 400;
  line-height: 56px;
  color: #000;
  text-transform: uppercase;
}

.hamburger-btns{
  display: none;
}

.menulist img {
  filter: invert(100%);
}


@media (max-width: 1440px){
  .menulist{
    gap: 4rem;
  }
  
  .menu-list-ul {
    gap: 2rem;
  }
}

@media (max-width: 1290px) {
  .menulist{
    gap: 2rem;
  }

  .menu-list-ul{
    gap: 1rem;
  }
}

@media (max-width: 1024px) {

  .hamburger-btns{
    display: flex;
  }

  .menu {
    position: fixed;
    background-color: #1a1d33;
    width: 100%;
    height: 100vh;
    left: 0%;
    top: -110%;
    transition: all 0.5s ease-out;
    padding: 10rem 0;
  }
  
  .menu.open {
    top: 0%;
  }

  .menu-list-ul{
    flex-direction: column;
  }

  .menulist{
    flex-direction: column;
    gap: 1rem;
    padding-left: 7%;
  }

  .scrolled .menu-list-ul li a {
    color: #fff;
  }

  .menu-list-ul li a{
    color: #fff;
  }
  
  .scrolled .dot {
    background-color: #000;
  }

  .scrolled .menulist img {
    filter: unset;
  }

  .home.scrolled .navlink{
    color: #fff;
  }

  .menulist img{
    filter: unset;
  }
  
  .home.scrolled .menulist img{
    filter: unset;
  }

  header{
    padding: 0;
  }

}

@media only screen and (max-width: 600px) {

  header{
    padding: 0;
  }
  .header-container {
    width: 90%;
  }

  .menulist {
    padding-left: 5%;
  }

  .menu {
    padding: 8rem 0;
  }

  .menu-list-ul li a {
    font-size: 18px;
  }

  .logo svg {
    width: 60%;
  }

  .three-dots {
    width: 60px;
    height: 60px;
  }

  .dot {
    width: 5px;
    height: 5px;
  }
}

@media only screen and (max-width: 456px) {

}
