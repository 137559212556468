.governance {
    background-color: #F7F8F1;
}

.governance-flex {
    display: flex;
    justify-content: space-around;
    margin-top: 6rem;
}

.governance-sec {
    background-color: #D8E5D8;
    position: relative;
    width: calc(24% - 4rem);
    transition: all .5s ease;
}

.governance-sec:hover {
    background-color: #fff;
    box-shadow: 5px 5px 5px 0px #0000001A;

}

.governance-img {
    width: 100%;
    transform: translate(-38px, -25px);
}

.governance-text {
    padding: 0 1rem 2rem 1rem;
}

.governance-text h2 {
    font-size: 26px;
}

.governance-text h3 {
    font-size: 22px;
}

@media (max-width:1440px) {
    .governance-text h2 {
        font-size: 22px;
    }

    .governance-text h3 {
        font-size: 20px;
    }
}

@media (max-width:1366px) {
    

    .governance-img {
        width: 94%;
        transform: translate(-18px, -22px);
    }

    .governance-text {
        padding: 0 1rem 1.2rem 1rem;
    }
}

@media (max-width:1280px) {
    .governance-text h2 {
        font-size: 20px;
    }

}

@media (max-width:1200px) {

    .governance-flex {
        flex-wrap: wrap;
        gap: 6rem 0;
    }

    .governance-sec {
        width: calc(50% - 4rem);
    }
}


@media (max-width:1024px) {
    .governance-flex {
        flex-wrap: wrap;
        gap: 6rem 0;
    }

    .governance-sec {
        width: calc(50% - 4rem);
    }
}

@media (max-width:600px) {
    .governance-sec {
        width: 100%;
    }

    .governance-text {
        padding: 1rem;
    }

    .governance-flex {
        margin-top: 2rem;
        gap: 2rem 0;
    }

    .governance-text h2 {
        font-size: 18px;
        margin-bottom: 0.2rem;
    }

    .governance-text h3 {
        font-size: 16px;
    }

    .governance-img {
        width: 100%;
        transform: unset;
    }
    

}