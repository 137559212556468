.heritage {
  background-color: #f9f9f9;
}

.heritage-flex {
  display: flex;
  justify-content: flex-start;
  gap: 5rem;
  align-items: flex-start;
}

.heritage-sec-holder {
  width: 48%;
}

.heritage-sec {
  margin-top: 2rem;
}

.heritage-sec h3 {
  font-family: "Gothic Bold", sans-serif;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 0.6rem;
}


.heritage-img {
  width: 50%;
}

.heritage-img img {
  width: 100%;
  box-shadow: 5px 5px 5px 0px #0000001a;
}

/* .abt-video {
    background-color: #ECECEC;
    margin-top: 5rem;
} */

.abt-video {
  background-color: #ececec;
  margin-top: 5rem;
  text-align: center;
  padding: 2rem 0;
  height: 50vh;
  width: 100%;
}

.abt-video iframe {
  width: 100%;
  height: 100%;
  /* Set the height to 100% of the parent container */
}

@media (max-width: 1366px) {
  .heritage-sec-holder {
    width: 60%;
  }

  .heritage-sec {
    margin: 1rem 0;
  }
}

@media (max-width: 1024px) {
  .heritage-flex {
    gap: 2rem;
    flex-direction: column;
  }

  .heritage-sec-holder {
    width: 100%;
  }

  .heritage-img {
    width: 100%;
  }

  .heritage-img img {
    width: 100%;
  }

  .heritage-sec h3 {
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  .abt-video {
    height: 40vh;
  }
}

@media (max-width: 600px) {
  .abt-video {
    height: 100%;
  }

  .heritage-sec h3 {
    font-size: 26px;
  }
}
