.bannervideo {
  height: 100vh !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: relative;
}

.banner-slider {
  position: relative;
}

/* .banner-slider::after {
  content: "";
  position: absolute;
  background: #1a1d3380;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2;
} */

.banner-slider video {
  position: absolute;
  top: 0;
  left: 0;
  /* width: 100%; */
}

.slide-content {
  position: relative;
  z-index: 3;
  opacity: 0;
  transition: all 0.8s ease;
  text-align: center;
}

.slide-content h2 {
  color: #fff;
  font-size: 52px;
}

.slide-content h3 {
  color: #fff;
}

.banner-swiper-container .swiper-wrapper {
  background-image: linear-gradient(to top, #000d, #0003);
}

.banner-swiper-container .swiper-slide {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.banner-swiper-container .swiper-slide-active {
  opacity: 1;
}

.banner-swiper-container .swiper-slide-active .slide-content {
  opacity: 1;
}


@media (max-width: 1024px) {
  .bannervideo {
    height: 60vh !important;
  }

  .slide-content h2 {
    font-size: 42px;
  }

}

@media (max-width: 676px) {
  .bannervideo {
    height: 80vh !important;
  }

  .slide-content h2 {
    text-align: center;
  }

  .slide-content h3 {
    text-align: center;
    font-size: 22px;
  }

  .slide-content h2 {
    font-size: 30px;
  }
}