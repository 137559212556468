.passion{
    background-color: #fff;
    padding: 5rem 0;
}
.passion h2{
    margin-bottom: 1.5rem;
}
.passion p{
    width: 80%;
}

@media (max-width:1024px){
    .passion p{
        width: 100%;
    }
    .passion {
        background-color: #fff;
        padding: 3rem 0;
    }
    .passion h2 {
        margin-bottom: 1rem;
    }
}