.blogs-intro h2 {
    margin-bottom: 1.2rem;
}

.blogs-intro p {
    font-size: 16px;
}

.blogs-sec-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5rem 3.3rem;
    margin-top: 4rem;
    align-items: stretch;
}

.blogs-section {
    width: calc(33% - 3%);
    background: #D8E5D8;
    transition: all .5s ease;
}

.blogs-section:hover {
    background: #fff;
    box-shadow: 5px 5px 5px 0px #0000001A;
}

.blogs-section img {
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.blogs-section-text {
    padding: 1.5rem 1.2rem 3rem;
}

.blogs-section h3 {
    font-family: "Gothic Bold", sans-serif;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 0.6rem;
}

.blogs-section p {
    font-family: "Gothic";
    font-size: 16px;
}

@media (max-width: 1440px) {
    .blogs-sec-flex {
        gap: 3rem;
    }
}

@media (max-width:1366px) {
    .blogs-sec-flex {
        gap: 2rem;
    }
}

@media (max-width:1024px) {
    .blogs-section {
        width: calc(50% - 3%);
    }
}

@media (max-width:600px) {
    .blogs-section {
        width: 100%;
    }
}