.initiatives-section {
  background: #f7f8f1;
}

.initiatives-flex {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 0;
}

.initiatives-content {
  width: 32%;
}

.initiatives-content h2 {
  font-size: 30px;
  line-height: 1.3;
}

.initiatives-content h3 {
  margin-bottom: 2rem;
}

.initiatives-content p {
  margin-bottom: 3rem;
  color: #000;
}

.initiatives-slider {
  width: 62%;
}

.initiatives-slider-wrapper {
  display: flex;
}

.initiatives-swiper .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: stretch;
  height: unset;
  padding: 1rem 0;
}

.initiatives-swiper .swiper-slide-next {
  margin: 0 -15px;
}

.initiatives-swiper .swiper-scrollbar-drag {
  background: #e1ebe1;
}

.initiatives-slide-flex {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #d8e5d8;
  transition: all 0.5s ease;
}

.initiatives-text-heading {
  padding: 0rem 1.5rem 0rem 1.5rem;
}

.initiatives-text-p {
  padding: 0rem 1.5rem 3rem 1.5rem;
}

.initiatives-slide-flex h3 {
  font-size: 24px;
  font-weight: 400;
  font-family: "Gothic Bold", sans-serif;
  margin: unset;
}

.initiatives-slide-flex p {
  color: #000;
  font-weight: 300;
}

.initiatives-slide-flex img {
  width: 100%;

  transition: all 0.5s ease;
}

.initiatives-img-holder {
  overflow: hidden;
  line-height: 0;
  /* border-top-left-radius: 15px;
  border-top-right-radius: 15px; */
}

.initiatives-slide-flex:hover img {
  transform: scale(1.5);
}

.initiatives-slide-flex:hover {
  background-color: #1a1d33;
  box-shadow: 5px 5px 10px 0px #0000001a;
}
.initiatives-slide-flex:hover h3 {
  color: #fff;
}

.initiatives-slide-flex:hover p {
  color: #fff;
}

.initiatives-flex-end-p {
  color: #000;
  margin-top: 3rem;
  width: 90%;
}

/* @media (max-width: 1440px) {
 
  .initiatives-flex {
    flex-direction: column;
    gap: 3rem;
  }

  .initiatives-content {
    width: 100%;
  }

  .initiatives-slider {
    width: 100%;
    margin-top: 1rem;
  }
} */

@media (max-width: 1440px) {
  .initiatives-flex {
    flex-direction: column;
    gap: 3rem;
  }

  .initiatives-content {
    width: 100%;
  }

  .initiatives-slider {
    width: 100%;
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 1280px) {
  .initiatives-content {
    width: 100%;
  }

  .initiatives-slider {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .initiatives-content p {
    font-size: 18px;
  }

  .initiatives-content h2 {
    font-size: 24px;
  }

  .initiatives-content h3 {
    font-size: 22px;
  }
}
