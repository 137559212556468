.canvas {
  background: linear-gradient(180deg, #FFF1E6 0%, rgba(223, 231, 253, 0.5) 99.96%, #CDDBFD 99.97%, rgba(205, 218, 253, 0) 99.98%, #FDE2E4 99.99%, #FFF1E6 100%, #A97A54 100%);
}

.canvas{
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.canvas-header {
  text-align: center;
}

.canvas-header h3 {
  margin-bottom: 2rem;
}

.canvas-header p {
  width: 70%;
  margin: auto;
  color: #000;
}

.canvas-container {
  position: relative;
  margin-left: 6%;
  text-align: center;
}

.product-explore{
  width: 100%;
  display: flex;
  justify-content: center;
}

.product-card{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;
  /* width: 100%; */
}

.product-content{
  padding: 1rem;
}

.product-content p{
  font-weight: 400;
}

.product-img{
  line-height: 0;
  /* width: 100%; */
}

.product-img img{
  width: 100%;
}

.product-swiper{
  padding: 2rem 0;
}



@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 768px) {

}

@media (max-width: 600px) {
  .canvas{
    gap: 1rem;
  }

  .product-content p{
    font-size: 16px;
  }
}

