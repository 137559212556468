.blog-section {
  background: #f9f9f9;
  background: linear-gradient(89.94deg, #FFF1E6 0.06%, #FDE2E4 23.36%, #DFE7FD 44.02%, #CDDBFD 68.98%, #CDDAFD 68.98%, #FFF1E6 98.7%);
padding: 6rem 0;
}

.blog-flex {
  display: flex;
  justify-content: space-between;
}

.blog-content {
  width: 25%;
}

.blog-slides {
  width: 73%;
}

.blog-text p:nth-child(1) {
  font-size: 16px;
}

.blog-text p:nth-child(2) {
  font-size: 16px;
  font-weight: 400;
  margin-top: 0.5rem;
}

.blog-swiper {
  display: flex;
  gap: 2rem;
  position: relative;
  z-index: 0;
  padding: 1.5rem;
}

.blog-swiper .swiper-slide {
  height: unset;
  display: flex;
  align-items: stretch;
}

.blog-img-holder {
  overflow: hidden;
  /* border-top-left-radius: 15px;
  border-top-right-radius: 15px; */
}

.blog-img-holder img {
  width: 100%;

  transition: all 0.5s ease;
}

.blog-slide-flex:hover img {
  transform: scale(1.3);
}

.blog-slide-flex:hover {
  box-shadow: 0px 15px 15px 0px #0000001a;
}

.blog-swiper .swiper-wrapper {
  justify-content: space-between;
}

.blog-slide-flex {
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 5px 15px 0px #0000000d;
  transition: all 0.5s ease;
}

.blog-text {
  padding: 1rem;
}
/* 
.blog-swiper .swiper-scrollbar-drag {
  background: rgb(255 241 230);
} */



@media (max-width: 1366px) {
  .blog-content h3 {
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 1280px) {
  .blog-flex {
    display: flex;
    gap: 3rem;
    flex-direction: column;
  }

  .blog-slides {
    width: 100%;
  }

  .blog-content {
    width: 100%;
  }
}


@media (max-width: 600px) {


  .blog-flex {
    gap: 2rem;
  }

  .blog-section{
    padding: 4rem 0;
  }
}
