.growth-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4rem;
}

.growth--sec {
  position: relative;
  width: 100%;
  height: 100%;
}

.growth--sec p {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
  text-align: center;
  width: 40%;
  margin: 1rem auto;
  color: #000;
  min-height: 90px;
}

.timeline-holder {
  position: relative;
}

.timeline-holder img {
  width: 100%;
  height: 1px;
  position: relative;
}

.timeline-dot {
  background: #b9b9b9;
  border-radius: 50%;
  height: 1vw;
  width: 1vw;
  transition: background-color 0.3s ease;
}

.active-dot .timeline-dot {
  background: #000;
  height: 1.2vw;
  width: 1.2vw;
}

.hidden-para {
  display: none;
}

.active-para {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.year-sec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  width: 8%;
  margin: auto;
  align-items: center;
  cursor: pointer;
  gap: 1rem;
}

.year-sec p {
  font-size: 18px;
  font-family: "Gothic";
  text-align: center;
  font-weight: 600;
}

.year-holder1 {
  position: absolute;
  top: 20%;
  left: 18%;
}

.year-holder2 {
  position: absolute;
  top: 20%;
  left: 28%;
}

.year-holder3 {
  position: absolute;
  top: 20%;
  left: 38%;
}

.year-holder4 {
  position: absolute;
  top: 20%;
  left: 48%;
}

.year-holder5 {
  position: absolute;
  top: 20%;
  left: 58%;
}

.year-holder6 {
  position: absolute;
  top: 20%;
  left: 68%;
}

.year-holder7 {
  position: absolute;
  top: 20%;
  left: 78%;
}

@media (max-width: 1440px) {
  .growth--sec p {
    width: 50%;
  }



  .year-holder1 {
    top: 30%;
    left: 15%;
  }

  .year-holder2 {
    top: 30%;
    left: 25%;
  }

  .year-holder3 {
    top: 30%;
    left: 35%;
  }

  .year-holder4 {
    top: 30%;
    left: 45%;
  }

  .year-holder5 {
    top: 30%;
    left: 55%;
  }

  .year-holder6 {
    top: 30%;
    left: 65%;
  }

  .year-holder7 {
    top: 30%;
    left: 75%;
  }
}

@media (max-width: 1280px) {
  .growth-flex {
    gap: 2rem;
  }

  .year-holder1 {
    top: 30%;
    left: 18%;
  }

  .year-holder2 {
    top: 30%;
    left: 28%;
  }

  .year-holder3 {
    top: 30%;
    left: 38%;
  }

  .year-holder4 {
    top: 30%;
    left: 48%;
  }

  .year-holder5 {
    top: 30%;
    left: 58%;
  }

  .year-holder6 {
    top: 30%;
    left: 68%;
  }

  .year-holder7 {
    top: 30%;
    left: 78%;
  }
}

@media (max-width: 1024px) {
  .timeline-holder img {
    height: 4px;
  }

  .year-sec p {
    font-size: 16px;
  }

  .growth--sec p {
    width: 70%;
  }

  .growth--sec p {
    min-height: 70px;
  }

  .growth-flex {
    gap: 0;
  }

  

  .year-holder1 {
    top: 44%;
  }

  .year-holder2 {
    top: 44%;
  }

  .year-holder3 {
    top: 44%;
  }

  .year-holder4 {
    top: 44%;
  }

  .year-holder5 {
    top: 44%;
  }

  .year-holder6 {
    top: 44%;
  }

  .year-holder7 {
    top: 44%;
  }
}

@media (max-width: 768px) {
  .growth--sec p {
    margin: 2rem auto 0 auto;
    font-size: 18px;
  }

  .growth-flex {
    display: none;
    /* Hide desktop content on mobile */
  }

  .growth-flex-mobile {
    margin-top: 2rem;
  }
}

@media (min-width: 769px) {
  .growth-flex-mobile {
    display: none;
    /* Hide mobile content on desktop */
  }
}

@media (max-width: 600px) {
  .timeline-dot {
    height: 2vw;
    width: 2vw;
  }
  .active-dot .timeline-dot {
    height: 2.2vw;
    width: 2.2vw;
  }
  .growth--sec p {
    font-size: 15px;
    min-height: 60px;
    width: 100%;
  }

  .year-sec p {
    font-size: 14px;
  }

  .year-holder1 {
    top: 36%;
  }

  .year-holder2 {
    top: 36%;
  }

  .year-holder3 {
    top: 36%;
  }

  .year-holder4 {
    top: 36%;
  }

  .year-holder5 {
    top: 36%;
  }

  .year-holder6 {
    top: 36%;
  }

  .year-holder7 {
    top: 36%;
  }
}

@media (max-width: 400px) {
  .timeline-dot {
    height: 2.5vw;
    width: 2.5vw;
  }
  .active-dot .timeline-dot {
    height: 2.8vw;
    width: 2.8vw;
  }
  .year-sec p {
    font-size: 14px;
  }

  .year-holder1 {
    top: 36%;
  }

  .year-holder2 {
    top: 36%;
  }

  .year-holder3 {
    top: 36%;
  }

  .year-holder4 {
    top: 36%;
  }

  .year-holder5 {
    top: 36%;
  }

  .year-holder6 {
    top: 36%;
  }

  .year-holder7 {
    top: 36%;
  }
}
