

.initiativesflex {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 0;
  width: 100%;
}

.initiativescontent {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 7% ;
  gap: 2rem;
}

.initiativescontent:nth-child(2){
  background: #E9F2E5;
}

.initiativescontent img{
  width: 100%;
}

.initiativescontent div{
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.initiativescontent .initiatives h2{
  margin-bottom: 4rem;
}

.initiativescontent p {
  color: #000;
}

@media only screen and (max-width: 1280px){

  .initiativescontent div{
    gap: 0.8rem;
  }
}

@media only screen and (max-width: 768px) {
  .initiativescontent{
    width: 100%;
  }
  .initiativescontent .initiatives h2{
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 600px){
  .initiativessection{
    padding: 1rem 0;
  }
}
