.safety-side {
    background-color: #fff;
}

.safety-side-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.quality-flex {
    /* width: 47%; */
    width: 100%;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
}

.quality-flex-text {
    width: 78%;
}

.quality-flex-img {
    width: 20%;
    display: flex;
    flex-direction: column;
}

.quality-flex-img img:nth-child(1) {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    object-fit: contain;
}

.quality-flex-img img:nth-child(2) {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    object-fit: contain;
}



.machinery-flex {
    width: 48%;
    display: flex;
    justify-content: space-between;
}

.machinery-flex-text {
    width: 56%;
}

.machinery-flex-img {
    width: 40%;
}

.standards--text {
    margin-top: 4rem;
    width: 80%;
}


@media (max-width:1024px) {

    .safety-side-flex {
        flex-direction: column;
    }

    .quality-flex {
        width: 100%;
    }

    .machinery-flex {
        width: 100%;
        margin-top: 2rem;
    }

    .standards--text {
        margin-top: 2rem;
        width: 100%;
    }

    .machinery-flex-img img {
        width: 90%;
    }
}

@media (max-width:600px) {
    .quality-flex {
        flex-direction: column;
    }

    .quality-flex-text {
        width: 100%;
    }

    .quality-flex-img {
        width: 100%;
        display: flex;
        gap: 1rem;
        flex-direction: row;
    }

    .machinery-flex {
        flex-direction: column;
    }

    .machinery-flex-text {
        width: 100%;
    }

    .machinery-flex-img {
        width: 100%;
    }

    .machinery-flex-img img {
        width: 100%;
    }


    .quality-flex-img img:nth-child(1) {
        width: 40%;
    }

    .quality-flex-img img:nth-child(2) {
        width: 40%;
    }
}