.highlight-flex {
  margin-top: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.highlight-sec h2 {
  font-family: "Gothic";
  font-size: 70px;
  font-weight: lighter;
}
.highlight-sec p {
  font-weight: 400;
  text-align: center;
}

@media (max-width: 1280px) {
  .highlight-sec h2 {
    font-size: 50px;
  }
}

@media (max-width: 1024px) {
  .highlight-sec h2 {
    font-size: 40px;
  }
}
@media (max-width: 800px) {
  .highlight-sec h2 {
    font-size: 30px;
  }
  
  .highlight-flex {
    margin-top: 2rem;
  }
}
@media (max-width: 600px) {
  .highlight-sec h2 {
    font-size: 26px;
  }
}
@media (max-width: 567px) {
  .highlight-flex {
    flex-wrap: wrap;
  }
  .highlight-sec {
    width: 45%;
    margin-top: 1rem;
  }
  .highlight-sec p {
    text-align: left;
  }
}
