.contact--form {
    background-color: #2A398F;
    padding: 6rem 0;
}

.contact--form h2{
    color: #fff;
}

.contact--form .container{
 display: flex;
 flex-direction: column;
 gap: 2rem;
 align-items: center;
 justify-content: center;
}

.contact-flex {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 50%;
}

.contact-text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contact-text h3 {
    font-family: "Gothic Bold", sans-serif;
    font-size: 24px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 0.4rem;
}

.contact-text h3:nth-of-type(2),
.contact-text h3:nth-of-type(3) {
    margin-top: 2rem;
}

.contact-text address, a {
    font-family: "Gothic";
    font-size: 17px;
    font-weight: 300;
    color: #fff;
    font-style: normal;
}

.contact-form-sec {
    /* width: 50%; */
    display: flex;
    flex-direction: column;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.form-field{
    position: relative;
    width: 100%;
}

.form-field .ErrorMessage{
    position: absolute;
    top: 100%;
}

.contact--form input {
    color: #fff;
}
.contact--form select {
    color: #fff;
}
.contact-form input,
select {
    background: unset;
    outline: unset;
    border: unset;
    border-bottom: 1px solid #C5C5C5;
    padding: 1rem 0 0.5rem 0;
    font-size: 16px;
    font-weight: 400;
    font-family: "Gothic";
    width: 100%;
}

.contact-form input,
select  option{
    color: #000;
}

.contact-form input::placeholder {
    color: #C5C5C5;
}

.contact-form select::placeholder {
    color: #C5C5C5;
}

.contact-submit {
    padding: 0.8rem 2rem;
    border: 1px solid;
    color: #fff;
    position: relative;
    transition: all 0.9s ease;
    background-color: transparent;
    width: max-content;
    font-family: "Gothic";
    font-size: 16px;
    z-index: 0;
}

.contact-submit:hover {
    color: #000;
}

.contact-submit:before {
    content: "";
    width: 0;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #fff;
    z-index: -1;
    transition: width 0.5s ease;
}

.contact-submit:hover:before {
    width: 100%;
    background-color: #FFF;
}




@media (max-width: 1280px) {

    .contact-flex {
        margin-top: 1rem;
    }
    .contact-flex {
        width: 60%;
        gap: 2rem;
    }

    .contact-form{
        gap: 2rem;
    }

    .contact--form .container{
        gap: 1rem;
    }
}

@media (max-width: 1024px) {
    .contact-text h3 {
        font-size: 22px;
    }

    .contact-flex {
        width: 70%;
    }
}

@media (max-width: 768px) {
    .contact-flex {
        flex-direction: column;
    }

    .contact-text {
        width: 100%;
    }

    .contact-form-sec {
        width: 100%;
        margin-top: 2rem;
    }

    .contact-form input::placeholder {
        font-size: 16px;
    }

    .contact-form select::placeholder {
        font-size: 16px;
    }
    .contact-submit {
        font-size: 16px;
        padding: 0.6rem 2rem;
    }
    .contact-flex {
        width: 80%;
    }
}

@media (max-width: 600px) {
    .contact-text h3 {
        font-size: 20px;
    }
    .contact-form input::placeholder {
        font-size: 14px;
    }

    .contact-form select::placeholder {
        font-size: 14px;
    }  
    .contact-flex {
        width: 100%;
    }
    .contact--form{
        padding: 6rem 0;
    }
    .contact-form-sec{
        margin: 0;
    }
}