.introSection {
    padding: 5rem 0;
}

.introSection p {
    width: 80%;
}

@media (max-width:1024px) {
    .introSection p {
        width: 100%;
    }

    .introSection {
        padding: 4rem 0;
    }
}

@media (max-width:600px) {
    .introSection {
        padding: 3rem 0;
    }
}