@import url("./fonts.css");
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
  color: #000;
}

a {
  text-decoration: none;
  font-size: 17px;
  font-family: "Lato";
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}

p {
  color: #333333;
  font-size: 20px;
  font-weight: 400;
  font-family: "Lato";
  font-style: normal;
  /* line-height: 33.6px; */
}

h1 {
  font-size: 44px;
  font-weight: 600;
  color: #000;
  font-family: "Gothic";
  margin-bottom: 0.5rem;
  line-height: 1.3;
}

h2 {
  font-size: 42px;
  font-weight: 400;
  font-family: "Gothic";
  margin-bottom: 0.5rem;
  line-height: 1.3;
}

h3 {
  font-size: 28px;
  font-weight: 400;
  font-family: "Gothic";
  line-height: 1.3;
}

h4 {
  font-family: "Gothic";
  font-size: 26px;
  font-weight: 400;
  line-height: 1.3;
}

.container {
  width: 85%;
  margin: auto;
}

.padding {
  padding: 6rem 0;
}

.preloader-holder {
  align-items: center;
  background-color: #000000cc;
  border-radius: 3px;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
.ErrorMessage {
  font-size: 18px !important;
  margin-bottom: unset !important;
  color: red !important;
}

@media (max-width: 1440px) {
  .padding {
    padding: 3rem 0;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 30px;
  }
}

@media (max-width: 1024px) {
  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 28px;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 30px;
    margin: 0;
  }

  h3 {
    font-size: 22px;
  }

  .padding {
    padding: 2.5rem 0;
  }
}
