.innovation-section {
  background: #f9f9f9;
  padding: 14rem 0;
  background-image: url('../../../../public/images/innovation.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: color;
  background-color: #0008;
}

.innovation-div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.innovation-div h2 {
  color: #fff;
}

.innovation-div div:nth-child(1){
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.innovation-div div:nth-child(2){
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 2rem;
}

.innovation-div h3 {
  color: #fff;
}

.innovation-div p {
  width: 70%;
  text-align: center;
  color: #fff;
}

.innovation-div .common-btn{
  color: #fff;
  border: 1px solid #fff;
}




@media only screen and (max-width: 1280px) {

}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 768px) {
  .innovation-section{
    padding: 8rem 0;
  }
}

@media (max-width: 600px) {
  .innovation-section{
    padding: 6rem 0;
  }
  .innovation-div p{
    width: 100%;
  }

  .innovation-div{
    gap: 2rem;
  }
  .innovation-div h2 {
    font-size: 26px;
  }
}
