.homepage-career {
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../../../public/images/talent.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-blend-mode: color;
    background-color: #0008;
}

.career-center{
    position: absolute;
    left: 50%;
    top: 5%;
    transform: translateX(-50%);
}

.career-center h2 {
    font-weight: 300;
    font-family: 'Gothic';
    font-size: 42px;
    color: #fff;
    margin-top: 3rem;
}

.career-link{
    display: flex;
    gap: 2rem;
    align-items: center;
}

.career-link p{
    color: #fff;
    font-size: 26px;
    
}


@media (max-width: 1024px){
    .homepage-career{
        height: 75vh;
    }
}

@media (max-width: 768px){
    .homepage-career{
        height: 60vh;
    }
}

@media (max-width: 600px){
    .homepage-career{
        height: 50vh;
    }
}