.common-btn {
    padding: 0.5rem 2rem;
    border: 1px solid #1A1D33;
    color: #1A1D33;
    position: relative;
    transition: all 0.9s ease;
    background-color: transparent;
    width: max-content;
    z-index: 0;
    font-size: 17px;
    font-weight: 400;
}

.common-btn:hover {
    color: #fff;
}

.common-btn:before {
    content: "";
    width: 0;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #1A1D33;
    z-index: -1;
    transition: width 0.5s ease;
}

.common-btn:hover:before {
    width: 100%;
}

/* @media (max-width:768px) {
    .common-btn {
        font-size: 18px;
    }
} */